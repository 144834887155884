<template lang="pug">
.vc-info-link-info-box(:class="infoLink.use_type")
  .inner
    a.image-wrapper(
      :href="infoLink.url"
      target="_blank"
      ref="noopenner noreferrer"
      @click="sendPromoEvent"
    )
      img.image(v-lazy="infoLink.cover.url")

    //- .info-wrapper
    //-   .info-title
    //-     | {{ infoLink.title }}
    //-   .info-sub-title
    //-     | {{ infoLink.sub_title || '&nbsp;' }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    infoLink: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    async sendPromoEvent() {
      await this.$store.dispatch('ga4Operation', [
        [
          'event',
          'td_about',
          {
            td_position: `${this.infoLink.title} —— ${this.infoLink.sub_title}`
          }
        ]
      ])
    }
  }
}
</script>
