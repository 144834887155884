<template lang="pug">
section.vc-product-variants-selector(v-if="selectedVariant")
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .content-wrapper
    //- HEADER
    .content-header
      .picture-box
        img.picture(:src="displayPicture")
      .product-info
        h1.name {{ product.name }}
        a.brand(
          :href="brandLink"
          v-if="product.brand_id"
        ) {{ product.brand_name }}

    .info-wrapper
      .info-unit.price
        .price
          span.type(
            v-if="product.hasDiscount()"
            :data-currency="currentCurrency"
          ) {{ subDiscountedTotal.format() }}
          span.type(:data-currency="currentCurrency") {{ subTotal.format() }}
      bonus-reward-info(
        :selected-variant="selectedVariant"
        :quantity="quantity"
      )

    .spec-description(v-if="isVariantsLoaded && !!selectedVariant.description")
      .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

    //- BODY
    .content-body(v-if="hasSelectableVariants")
      template(v-if="product.is_allow_backorder")
        .variants-list.has-message
          .label {{ actionLocaleText('select_x', { x: modelNameLocaleText('product/variant') }) }}
          .variant-unit(
            v-if="isVariantsLoaded"
            v-for="variant in variants"
            :key="variant.id"
            :class="variantSelectedClass(variant)"
            @click="selectVariant(variant)"
          )
            .message
              template(v-if="variant.is_allow_backorder")
                span.allow-backorder {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
              template(v-else)
                span.sold-out(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
            .name {{ variant.name }}
            .icon
              i.fa.fa-circle-o
      template(v-else)
        .variants-list(
          :class="{ 'has-message': variants.some((variant) => variant.stock < 1) }"
        )
          .variant-unit(
            v-if="isVariantsLoaded"
            v-for="variant in variants"
            :key="variant.id"
            :class="variantSelectedClass(variant)"
            @click="selectVariant(variant)"
          )
            .message
              span.sold-out(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
              span.allow-backorder(v-if="variant.is_allow_backorder") {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
            .name {{ variant.name }}
            .icon
              i.fa.fa-circle-o
    //- FOOTER
    .content-footer
      delivery-weekdays-selector(
        v-if="isVariantsLoaded"
        :variant="selectedVariant"
        :pre-selected-weekdays="preSelectedWeekdays"
        @delivery-weekday-selected="deliveryWeekdaySelectedHandler"
      )
      quantity-picker(
        v-if="isVariantsLoaded"
        :quantity.sync="quantity"
        :current-stock="currentStock"
        :current-variant="selectedVariant"
        :current-order-item="currentOrderItem"
        :is-allow-backorder="selectedVariant.is_allow_backorder"
      )

      .action-bar(v-if="isSubscription")
        subscription-button(
          :class="{ 'is-loading': isProcessing }"
          :variant-id="selectedVariant.id"
          :quantity="quantity"
        )
      .action-bar(v-else)
        template(v-if="isPurchasable")
          .button.is-odd.is-outlined(
            :class="{ 'is-loading': isProcessing }"
            @click="buyNow"
          ) {{ actionLocaleText('buy_now') }}
          .button.is-odd(
            :class="{ 'is-loading': isProcessing }"
            @click="addToCart"
          ) {{ actionLocaleText('add_to_cart') }}
        template(v-else)
          .button.is-light(
            :class="{ 'is-loading': isLoading }"
            disabled
          ) {{ copyLocaleText('stock_status.no_stock') }}
</template>

<script>
import BonusRewardInfo from './bonus-reward-info.vue'
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import SubscriptionButton from './subscription-button.vue'

export default {
  components: {
    BonusRewardInfo,
    SubscriptionButton
  },

  mixins: [productVariantSelectableMixin],

  props: {
    preSelectedVariantId: {
      type: String,
      required: false
    },

    preSelectedQuantity: {
      type: Number,
      required: false
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.product.id)
    },

    selectedVariant() {
      if (this.isVariantsLoaded) {
        return this.variants.find(
          (variant) => variant.id === this.selectedVariantId
        )
      } else {
        return this.product
      }
    },

    currentOrderItem() {
      return this.cartItems.find(
        (item) => item.variant_id === this.selectedVariantId
      )
    },

    displayPicture() {
      if (this.isVariantsLoaded) return this.selectedVariant.displayPicture()
    },

    hasSelectableVariants() {
      return this.variants.length > 1
    },

    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedVariant.id,
            quantity: this.quantity
          }
        }
      }
    }
  },

  watch: {
    selectedVariantId() {
      if (this.currentOrderItem === undefined) {
        this.quantity =
          this.preSelectedQuantity || this.selectedVariant.min_purchase_quantity
      } else {
        this.quantity = this.preSelectedQuantity || 1
      }
    }
  },

  // created() {},
  mounted() {
    if (this.variants.length === 0) {
      this.$store
        .dispatch('products/find', this.product.id)
        .then(() => {
          return this.setSelectedVariantId()
        })
        .then(() => {
          return this.setMaxHeightToContentBody()
        })
    } else {
      this.setSelectedVariantId()
      this.setMaxHeightToContentBody()
    }

    this._initializeCartService()
  },

  methods: {
    setSelectedVariantId() {
      this.selectedVariantId =
        this.preSelectedVariantId || this.masterVariant.id
    },

    setMaxHeightToContentBody() {
      const contentBody = this.$el.querySelector('.content-body')

      if (!contentBody) return

      contentBody.style.maxHeight = `calc(100vh
                                     - 160px
                                     - ${
                                       this.$el.querySelector('.content-header')
                                         .scrollHeight
                                     }px
                                     - ${
                                       this.$el.querySelector('.content-footer')
                                         .scrollHeight
                                     }px)`
    },

    variantSelectedClass(variant) {
      return {
        'is-selected': this.selectedVariantId === variant.id
      }
    },

    selectVariant(variant) {
      this.selectedVariantId = variant.id
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.$parent.close()
        })
        .catch((errors) => {
          this.isProcessing = false

          if (
            errors.response.status === 401 &&
            errors.response.data.code === 'token_expired'
          ) {
            this.$parent.close()
          }
          if (errors.response.status !== 422) return
          if (errors.response.data.code === 'min_purchase_quantity_failure')
            this.form.quantity = this.variant.min_purchase_quantity
          if (errors.response.data.code === 'max_purchase_quantity_failure')
            this.form.quantity = this.variant.max_purchase_quantity

          if (errors.response.data.validation_errors) {
            this.$store.dispatch('addFlashMessage', [
              'notice',
              Object.values(errors.response.data.validation_errors)[0].join(
                ', '
              )
            ])
          }
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$parent.close()
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          if (
            errors.response.status === 401 &&
            errors.response.data.code === 'token_expired'
          ) {
            this.$parent.close()
          }
          this.isProcessing = false
        })
    }
  }
}
</script>
