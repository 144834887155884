<template lang="pug">
.vc-product-bonus-reward-info(v-if="hasFeedbackFund")
  .info-unit.price
    .label {{ attributeLocaleText('product', 'bonus_reward') }}
    .price
      .icon
        i.fa.fa-product-hunt
      span.bonus {{ feedbackFundReward }}
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore, useCurrentUser, useMoney } from 'skid-composables'

const props = defineProps({
  selectedVariant: {
    type: Object,
    required: true
  },

  quantity: {
    type: Number,
    required: true
  }
})

const store = useStore()
const { isUserSignedIn } = useCurrentUser()
const { toMoney } = useMoney()

/**
 * @return {Boolean}
 */
const hasBonusReward = computed(() => {
  return (
    isRewardEnabled.value &&
    bonusReward.value > 0 &&
    !props.selectedVariant.is_subscription
  )
})

/**
 * @return {Boolean}
 */
const hasFeedbackFund = computed(() => {
  return isFeedbackFundEnabled.value && feedbackFundReward.value > 0
})

/**
 * @return {Boolean}
 */
const isRewardEnabled = computed(() => {
  return isUserSignedIn.value && rewardSettings.value?.enabled
})

/**
 *  @return {Boolean}
 */
const isFeedbackFundEnabled = computed(() => {
  return isRewardEnabled.value && rewardSettings.value?.feedback_fund.enabled
})

/**
 * @return {Object}
 */
const rewardSettings = computed(() => {
  return store.getters['siteConfigs/allConfigs'].rewards_program
})

/**
 * @return {Object}
 */
const product = computed(() => {
  return store.getters['products/find'](props.selectedVariant.product_id)
})

/**
 * @return {Number}
 */
const bonusReward = computed(() => {
  if (!isRewardEnabled.value || !product.value?.bonus_deductible) return 0

  return toMoney(
    Math.round(
      props.selectedVariant.consumer_price *
        props.quantity *
        parseInt(rewardSettings.value?.bonus_reward_rate || 0) *
        0.01
    )
  ).amount
})

/**
 * @return {Number}
 */
const feedbackFundReward = computed(() => {
  if (!isFeedbackFundEnabled.value || !product.value?.bonus_deductible) return 0

  return (
    parseInt(rewardSettings.value?.feedback_fund.bonus_reward || 0) *
    Math.floor(
      toMoney(props.selectedVariant.consumer_price * props.quantity).amount /
        parseInt(rewardSettings.value?.feedback_fund.purchase_amount || 0)
    )
  )
})

onMounted(async () => {
  if (isUserSignedIn) await store.dispatch('siteConfigs/fetchRewardsProgram')
})
</script>
