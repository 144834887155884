<template lang="pug">
.vc-content-news-bulletin-board
  .inner
    a.content-image(:href="newsBulletinBoard.url")
      img.image(v-lazy="newsBulletinBoard.cover")
    .content-info
      .content
        h3.title {{ newsBulletinBoard.title }}
        p.description {{ newsBulletinBoard.description }}

      a.button(:href="newsBulletinBoard.url")
        span {{ actionLocaleText('detail_info') }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    newsBulletinBoard() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].page_index
          ?.news_bulletin_board || {}
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
