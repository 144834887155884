<template lang="pug">
.vc-dashboard-bonus-points-box
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .content-wrapper
    //- HEADER
    .content-header
      .icon
        i.fa.fa-product-hunt
      .text
      span {{ modelNameLocaleText('user/bonus_credit_transaction') }}

    //- BODY
    .content-body
      .bonus-credit-info(v-if="currentUserData.isDataLoaded()")
        .label {{ attributeLocaleText('user/bonus_credit_transaction', 'credit_balance') }}
        .bonus-credit {{ moneyFromAmount(currentUserData.bonus_credit).format() }}
      .credit-transations
        .label {{ modelNameLocaleText('user/bonus_credit_transaction') }}
        b-table(
          :data="bonusCreditTransactions"
          backend-pagination
          :currentPage="currentPage"
          :perPage="pageSize"
          backend-sorting
          :default-sort="sortField"
          :default-sort-direction="sortOrder"
          @sort="onSort"
          :total="totalCount"
          :loading="isLoading"
          :hoverable="true"
          :mobile-cards="false"
        )
          b-table-column(
            v-slot="props"
            field="number"
            :label="attributeLocaleText('user/bonus_credit_transaction', 'order_number')"
            sortable
          )
            a.order-number(:href="`/user/orders/${props.row.order_token}`")
              span {{ props.row.order_number }}
          b-table-column(
            v-slot="props"
            field="created_at"
            :label="attributeLocaleText('user/bonus_credit_transaction', 'created_at')"
            sortable
          )
            span {{ dateTimeLocaleText(props.row.created_at, 'YYYY-MM-DD') }}
          b-table-column(
            v-slot="props"
            field="expired_at"
            :label="attributeLocaleText('user/bonus_credit_transaction', 'expired_at')"
            sortable
          )
            span {{ dateTimeLocaleText(props.row.expired_at, 'YYYY-MM-DD') }}
          b-table-column(
            v-slot="props"
            field="credit_amount"
            :label="attributeLocaleText('user/bonus_credit_transaction', 'credit_amount')"
            sortable
          )
            .credit-amount
              .icon
                i.fa.fa-product-hunt
              span {{ moneyFromAmount(props.row.credit_amount).format() }}
          b-table-column(
            v-slot="props"
            field="reason"
            :label="attributeLocaleText('user/bonus_credit_transaction', 'reason')"
            sortable
          )
            span {{ props.row.reason }}

  odd-pagination(
    :total="totalCount"
    :current.sync="currentPage"
    :per-page="pageSize"
    size="is-small"
    order="is-centered"
    :rounded="true"
    @change="onPageChange"
  )
</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'userBonusCreditTransactions',
      sortOrder: 'desc',
      sortField: 'created_at'
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    },

    bonusCreditTransactions() {
      return this.$store.getters['userBonusCreditTransactions/all']
    },

    hasBonusCreditTransactions() {
      return this.bonusCreditTransactions.length > 0
    }
  },
  created() {
    if (!this.currentUserData.isDataLoaded())
      this.$store.dispatch('users/find', this.currentUser.id)
    this.fetchingInitialData()
  },

  // mounted() {},
  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('users/fetchBonusCreditTransactions', {
        model: this.currentUserData,
        options: options
      })
    },

    orderUrl(bonusCreditTransaction) {
      if (!bonusCreditTransaction.order_token) return '#'

      return `/user/orders/${bonusCreditTransaction.order_token}`
    },

    linkTo(bonusCreditTransaction) {
      if (!bonusCreditTransaction.order_token) return

      Turbolinks.visit(`/user/orders/${bonusCreditTransaction.order_token}`)
    },

    updateQueryString(options) {}
  }
}
</script>
