<template lang="pug">
.vc-common-blog-category-menu
  ul.menu-items
    li.menu-item(
      v-for="category in categories"
      :key="category.id"
    )
      b-dropdown(
        :triggers="['click', 'hover']"
        :class="{ 'hidden-item': subCategories(category) <= 0 }"
        aria-role="list"
        hoverable
      )
        a.link(
          @click="sendClickEvent(category)"
          :href="`/categories/${category.id}/blogs`"
          slot="trigger"
        )
          span.text {{ category.name }}
          span.icon.is-small
            i.fa.fa-chevron-down(aria-hidden="true")
        b-dropdown-item(
          v-for="subCategory in subCategories(category)"
          :key="subCategory.id"
          aria-role="menuitem"
          has-link
        )
          a(
            @click="sendClickEvent(subCategory, { parent: category })"
            :href="`/categories/${subCategory.id}/blogs`"
          )
            span {{ subCategory.name }}
</template>

<script>
import BlogCategoryBasicMixins from '../mixins/blog_category_basic_mixin.js'

export default {
  // components: {},
  mixins: [BlogCategoryBasicMixins]
  // props: {},
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
