<template lang="pug">
.vc-content-card
  .inner
    a.content-image(
      href="#"
      @click.prevent="visitContent"
    )
      img.image(v-lazy="content.cover.url")
    .content-info
      .info
        span.date
          | {{ dateTimeLocaleText(content.published_at, 'YYYY-MM-DD') }}
      .info
        span.title
          | {{ content.title }}
      .info
        .content(v-html="content.cardContent()")
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    async visitContent() {
      await this.$store.dispatch('ga4Operation', [
        [
          'event',
          'td_news',
          {
            td_position: this.content.title
          }
        ]
      ])
      Turbolinks.visit(`/contents/${this.content.slug || this.content.id}`)
    }
  }
}
</script>
