<template lang="pug">
.vc-common-search-button.function-button
  b-dropdown(position="is-bottom-left")
    a.link(
      slot="trigger"
      href="#"
      @click.prevent=""
    )
      .icon.search.is-small
        .fa.fa-search
      //- span.text {{ actionLocaleText('search') }}
    b-dropdown-item(custom)
      b-field(:label="actionLocaleText('search')")
        input.input(
          v-model="searchText"
          :placeholder="messageLocaleText('extra_product_name_or_key_word')"
          @keydown.enter="search"
          @compositionend="isComposing = false"
          @compositionstart="isComposing = true"
        )
</template>

<script setup lang="ts">
import { ref } from 'vue'
import URIConvertService from '@services/uri_convert_service'
import { useStore } from 'skid-composables'

const searchText = ref('')
const isComposing = ref(false)
const store = useStore()

const search = async () => {
  if (isComposing.value) return
  if (searchText.value.length <= 0) return

  await store.dispatch('ga4Operation', [
    ['event', 'search', { search_term: searchText.value }],
    ['event', 'td_search', { td_position: searchText.value }]
  ])

  Turbolinks.visit(
    `/product-categories?q[variants_with_master_sku_or_brand_name_or_name_cont]=${URIConvertService.encode(
      searchText.value
    )}`
  )
}
</script>
