<template lang="pug">
.vc-order-option-buttons
  b-loading(:active.sync="isLoading")
  //- Comment
  comment-button(
    :order="order"
    :jwt-token="jwtToken"
    :class="isOnlyButtonState"
  )

  //- Detail Link
  a.button.is-outlined.is-default(
    v-if="showDetailLink"
    :href="orderDetailPath"
  ) {{ copyLocaleText('order_detail') }}

  //- Cancel
  a.button.is-outlined.is-default(
    v-if="order.canCancelBy(currentUser)"
    @click="confirmCancel"
  ) {{ actionLocaleText('cancel_order') }}

  //- Apply RMA
  a.button.is-outlined.is-default(
    v-if="order.canApplyForRma()"
    @click="applyRma"
  ) {{ actionLocaleText('apply_rma') }}

  //- Purchase Again
  a.button.is-outlined.is-default(
    href="#"
    @click.prevent="purchaseAgain"
  ) {{ actionLocaleText('purchase_again') }}

  //- Payment
  .button.is-outlined.is-odd(
    v-if="canMakePayment"
    @click="makePayment"
    :class="{ 'is-loading': isProcessing }"
  ) {{ actionLocaleText('make_payment') }}
  form(
    :action="gatewayApiBase"
    method="post"
    ref="payment-form"
  )
</template>

<script>
import queryString from 'query-string'
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'
import paymentButtonMixin from '../mixins/payment_button_mixin.js'
import CommentButton from './comment-button.vue'
import cancelByRecipientForm from './cancel-by-recipient-form.vue'

export default {
  components: {
    CommentButton
  },

  mixins: [paymentButtonMixin],

  props: {
    showDetailLink: {
      type: Boolean,
      required: false,
      default: true
    },

    jwtToken: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      isOnlyOneOption: false,
      isLoading: false
    }
  },

  computed: {
    canMakePayment() {
      return (
        this.order.canMakePayment() &&
        ['checkout', 'failed'].includes(this.payment.state)
      )
    },

    payment() {
      return this.$store.getters['orderPayments/find'](this.order.payment.id)
    },

    orderDetailPath() {
      return `/user/orders/${this.order.id}`
    },

    applyRmaPath() {
      if (window.location.pathname.includes('/guest/orders/')) {
        const params = queryString.parse(window.location.search)
        return `/guest/apply_rma/${this.order.email}?token=${params.token}`
      }
      if (window.location.pathname.includes('/search_order')) {
        return `/guest/apply_rma/${this.order.email}?token=${this.jwtToken}`
      }

      return `/user/orders/${this.order.id}/apply_rma`
    },

    isOnlyButtonState() {
      return {
        'is-alone': this.isOnlyOneOption
      }
    }
  },
  // created() {},
  mounted() {
    this._detactButtonLength()
  },

  methods: {
    purchaseAgain() {
      this.isLoading = true
      let timer = 0
      this.$store
        .dispatch('users/purchaseAgain', this.order.id)
        .then((response) => {
          Promise.all(
            response.data.messages.map((message) => {
              timer++
              return this.$store.dispatch('addFlashMessage', [
                'notice',
                message
              ])
            })
          ).then((_) => {
            if (response.data.item_added) {
              setTimeout(() => {
                Turbolinks.visit('/cart')
              }, Math.min(timer, 5) * 1000)
            } else {
              this.isLoading = false
            }
          })
        })
        .catch((errors) => {
          console.error(`errors`, errors)
          this.isLoading = false
        })
    },

    async confirmCancel() {
      await this.$store.dispatch('ga4Operation', [
        ['event', 'td_cancel', { td_position: '取消訂單' }]
      ])
      if (this.jwtToken) {
        this.openCancelByRecipientForm()
      } else {
        await new ActionConfirmService({
          title: this.messageLocaleText(
            'confirmations.are_you_sure_want_to_cancel_order'
          ),
          text: this.messageLocaleText(
            'confirmations.this_action_can_not_retrieve'
          )
        }).confirm(this.cancelOrder)
      }
    },

    async applyRma() {
      await this.$store.dispatch('ga4Operation', [
        ['event', 'td_refund', { td_position: '申請退貨' }]
      ])

      Turbolinks.visit(this.applyRmaPath)
    },

    openCancelByRecipientForm() {
      this.$buefy.modal.open({
        parent: this,
        component: cancelByRecipientForm,
        props: {
          jwtToken: this.jwtToken
        },
        events: {
          'order-canceled': this.$emit('order-canceled')
        }
      })
    },

    cancelOrder() {
      this.$store
        .dispatch('orders/cancel', this.order)
        .then((_) => {
          return this.$emit('order-canceled')
        })
        .then((_) => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('order_canceled_successfully')
          ])
        })
    },

    _detactButtonLength() {
      if (this.showDetailLink) return

      if (this.$el.querySelectorAll('.button').length === 1) {
        this.isOnlyOneOption = true
      }
    }
  }
}
</script>
