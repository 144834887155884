<template lang="pug">
.vc-common-hot-blogs(v-if="blogIds.length > 0")
  .blog-section-body
    b-loading(:active.sync="isLoading")
    .section
      .left
        blog-card.large.vertical(
          v-if="firstBlog"
          :key="firstBlog.id"
          :blog="firstBlog"
        )
      .right
        blog-card.small.horizontal(
          v-for="blog in sideBlogs"
          :key="blog.id"
          :blog="blog"
        )
</template>

<script>
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    blogCount: {
      type: Number,
      required: false,
      default: () => {
        return 5
      }
    }
  },

  data() {
    return {
      blogIds: [],
      isLoading: true
    }
  },

  computed: {
    blogs() {
      return this.blogIds.map((id) =>
        this.$store.getters['promotionalArticles/find'](id)
      )
    },

    firstBlog() {
      return this.blogs[0]
    },

    sideBlogs() {
      return this.blogs.slice(1, 4)
    }
  },

  // created() {},

  mounted() {
    this._fetchLatestBlogs()
  },

  methods: {
    _fetchLatestBlogs() {
      this.$store
        .dispatch('promotionalArticles/all', {
          pageNumber: 1,
          pageSize: this.blogCount,
          sort: '-published_at',
          usage: 'blog',
          forSearch: true
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    }
  }
}
</script>
