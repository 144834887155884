<template lang="pug">
.vc-order-bonus-info(v-if="isRewardsProgramEnabled")
  span {{ attributeLocaleText('order', 'bonus_reward') }}
  .icon
    i.fa.fa-product-hunt
  span.bonus
    | {{ bonusReward }}
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore, useCurrentUser, useMoney } from 'skid-composables'

const props = defineProps({
  order: {
    type: Object,
    required: true
  }
})

const store = useStore()
const { isUserSignedIn } = useCurrentUser()
const { moneyFromAmount } = useMoney()

/**
 * @returns {Boolean}
 */
const isCartFlow = computed(() => {
  return ['cart', 'items_confirmed', 'confirmed'].includes(
    props.order.order_state
  )
})

/**
 * @returns {Boolean}
 */
const isRewardsProgramEnabled = computed(() => {
  if (isCartFlow.value)
    return isUserSignedIn.value && Number(props.order.bonus_reward) > 0

  return hasBonusRewardTransition.value
})

/**
 * @returns {Number}
 */
const bonusReward = computed(() => {
  if (isCartFlow.value)
    return moneyFromAmount(Math.round(props.order.bonus_reward)).format()

  return hasBonusRewardTransition.value
    ? hasBonusRewardTransition.value.credit_amount
    : 0
})

/**
 * @returns {Boolean}
 */
const hasBonusRewardTransition = computed(() => {
  return store.getters['userBonusCreditTransactions/all'].find(
    (transaction) => transaction.credit_amount > 0
  )
})
</script>
