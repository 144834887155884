<template lang="pug">
.vc-product-info-card
  .header-bar
    .info-column
      .tag-wrapper(v-if="isSubscription")
        .subscription-tag.bold {{ attributeLocaleText('product', 'subscription_products') }}
      .allow-backorder(v-if="product.is_allow_backorder")
        span.tag {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
      h1.name {{ product.name }}
      a.brand(
        :href="brandLink"
        v-if="product.brand_id"
      ) {{ product.brand_name }}

  .offers-info(v-if="variants.length > 0")
    .info-unit.price
      .price
        span.type(
          v-if="product.hasDiscount()"
          :data-currency="currentCurrency"
        ) {{ subDiscountedTotal.format() }}
        span.type(:data-currency="currentCurrency") {{ subTotal.format() }}
    bonus-reward-info(
      :selected-variant="selectedVariant"
      :quantity="quantity"
    )

  .options(v-if="variants.length > 0")
    .variant-selector.option-unit(v-if="variants.length > 1")
      template(v-if="product.is_allow_backorder")
        b-dropdown.content.has-message(
          v-model="selectedVariantId"
          @change="variantChangedHandler"
        )
          .variants-dropdown(
            role="button"
            slot="trigger"
          )
            .variant-name {{ selectedVariant.displayName() }}
            .clickable-indicator
              .icon
                i.fa.fa-chevron-down

          b-dropdown-item(
            v-for="variant in variants"
            :key="variant.id"
            :value="variant.id"
          )
            .message
              template(v-if="variant.is_allow_backorder")
                span.allow-backorder {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
              template(v-else)
                span.sold-out(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
            span.name {{ variant.name }}
            .icon
              i.fa.fa-circle-o
      template(v-else)
        b-dropdown.content(
          v-model="selectedVariantId"
          @change="variantChangedHandler"
          :class="{ 'has-message': variants.some((variant) => variant.stock < 1) }"
        )
          .variants-dropdown(
            role="button"
            slot="trigger"
          )
            .variant-name {{ selectedVariant.displayName() }}
            .clickable-indicator
              .icon
                i.fa.fa-chevron-down

          b-dropdown-item(
            v-for="variant in variants"
            :key="variant.id"
            :value="variant.id"
          )
            .message
              span.sold-out(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
            span.name {{ variant.name }}
            .icon
              i.fa.fa-circle-o

    .spec-description(v-if="isVariantsLoaded && !!selectedVariant.description")
      .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

    .quantity-selector.option-unit
      quantity-picker.content(
        :quantity.sync="quantity"
        :current-stock="currentStock"
        :current-variant="selectedVariant"
        :is-allow-backorder="selectedVariant.is_allow_backorder"
      )

    delivery-weekdays-selector(
      v-if="isVariantsLoaded"
      :variant="selectedVariant"
      @delivery-weekday-selected="deliveryWeekdaySelectedHandler"
    )

  .action-bar(v-if="isSubscription")
    collect-button(:product="product")
    subscription-button(
      :class="{ 'is-loading': isProcessing }"
      :variant-id="selectedVariant.id"
      :quantity="quantity"
    )
  .action-bar(v-else)
    collect-button(:product="product")
    template(v-if="isPurchasable")
      .button.is-odd.is-inverted(
        :class="{ 'is-loading': isProcessing }"
        @click="buyNow"
      ) {{ actionLocaleText('buy_now') }}
      .button.is-odd(
        :class="{ 'is-loading': isProcessing }"
        @click="addToCart"
      ) {{ actionLocaleText('add_to_cart') }}
    template(v-else)
      .button.is-light(disabled) {{ copyLocaleText('stock_status.no_stock') }}

  .sticky-action-bar
    collect-button(:product="product")
    purchase-button(
      :product="product"
      :pre-selected-variant-id="selectedVariantId"
      :pre-selected-quantity="quantity"
      :pre-selected-weekdays="selectedDeliveryWeekdays"
    )
</template>

<script>
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import Form from 'odd-form_object'
import BonusRewardInfo from './bonus-reward-info.vue'
import CollectButton from './collect-button.vue'
import PurchaseButton from './purchase-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'
import SubscriptionButton from './subscription-button.vue'

export default {
  components: {
    BonusRewardInfo,
    CollectButton,
    PurchaseButton,
    Countdown,
    SubscriptionButton
  },

  mixins: [productVariantSelectableMixin],

  props: {
    variants: {
      type: Array,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    selectedVariant() {
      return this.variants.find(
        (variant) => variant.id === this.selectedVariantId
      )
    },

    currentOrderItem() {
      return this.cartItems.find(
        (item) => item.variant_id === this.selectedVariantId
      )
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedVariant.id,
            quantity: this.quantity
          }
        }
      }
    }
  },

  watch: {
    masterVariant(newValue) {
      this.selectedVariantId = newValue.id
    },

    selectedVariantId() {
      if (this.currentOrderItem === undefined) {
        this.quantity = this.selectedVariant.min_purchase_quantity
      } else {
        this.quantity = 1
      }
    }
  },

  // created() {},
  mounted() {
    this._setPaddingBottom()
    this._initializeCartService()
  },

  methods: {
    variantChangedHandler() {
      this.$nextTick(() => this.$emit('variant-changed', this.selectedVariant))
    },

    _setPaddingBottom() {
      const actionBarHeight = this.$el.querySelector('.action-bar').offsetHeight

      this.$el.style.paddingBottom = `${actionBarHeight}px`
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.quantity = 1
          this.selectedVariantId = this.masterVariant.id
        })
        .catch((errors) => {
          this.isProcessing = false
          if (errors.response.status !== 422) return
          if (errors.response.data.code === 'min_purchase_quantity_failure')
            this.form.quantity = this.variant.min_purchase_quantity
          if (errors.response.data.code === 'max_purchase_quantity_failure')
            this.form.quantity = this.variant.max_purchase_quantity

          if (errors.response.data.validation_errors) {
            this.$store.dispatch('addFlashMessage', [
              'notice',
              Object.values(errors.response.data.validation_errors)[0].join(
                ', '
              )
            ])
          }
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          this.isProcessing = false
        })
    },

    countdownEndedHandler() {
      setTimeout(() => {
        this.$emit('countdown-end')
      }, 3000)
    }
  }
}
</script>
