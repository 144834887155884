<template lang="pug">
.vc-order-cart-confirmation.odd-container.has-padding
  b-loading(:active="isLoading")

  cart-item-list(
    :order="order"
    :cart-items="cartItems"
    :gift-items="giftItems"
    :current-step="currentStep"
    :cart-service="cartService"
  )

  .form-container
    .form-column.-clean
      .section-label(:class="errors.errorClassAt('shipping_method_id')")
        | {{ modelNameLocaleText('shipping_method') }}
      .form-section(:class="errors.errorClassAt('shipping_method_id')")
        shipping-method-selector(
          :selected-shipping-method-id.sync="selectedShippingMethodId"
          :cart-items="cartItems"
        )
      .form-section
        promo-code-checker(:order="order")
      .form-section(v-if="isUserSignedIn")
        bonus-deduction-checker(
          :order="order"
          :enable-bonus-deduction.sync="enableBonusDeduction"
        )

    .form-column.-clean
      .section-label {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        price-detail(:order="order")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(
      @click="confirmItems"
      :class="{ 'is-loading': isLoading }"
    ) {{ actionLocaleText('next_step_is', { content: copyLocaleText('address_and_payment_method') }) }}
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'
import PromoCodeChecker from './promo-code-checker.vue'
import BonusDeductionChecker from './bonus-deduction-checker.vue'
import BackToStoreButton from './back-to-store-button.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    CartItemList,
    ShippingMethodSelector,
    PromoCodeChecker,
    BonusDeductionChecker,
    BackToStoreButton
  },

  mixins: [checkoutFlowMixin],
  // props: {},

  data() {
    return {
      selectedShippingMethodId: undefined,
      enableBonusDeduction: false
    }
  },

  watch: {
    selectedShippingMethodId() {
      this.errors.clear('shipping_method_id')
    }
  },

  computed: {
    giftItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']('gift')
    },

    bonusAdjustments() {
      return this.$store.getters['orders/allAdjustments'].filter(
        (adjustment) =>
          adjustment.title ===
          this.copyLocaleText('reward_program.bonus_credit_deduction')
      )
    }
  },
  // created() {},
  mounted() {
    if (this.order.shipments.length > 0)
      this.selectedShippingMethodId = this.shippingMethodFormOrderShipment.id
    this.enableBonusDeduction = this.bonusAdjustments.length > 0

    this._trackCheckoutProgress()
    this.$store.dispatch('ga4Operation', [
      generateGA4Events('begin_checkout', {
        items: this.cartItems,
        variants: this.$store.getters['productVariants/all'],
        value: this.toMoney(this.order.total).amount
      }),
      ['event', 'td_begin_checkout', { td_position: '開始結帳' }]
    ])
  },

  methods: {
    backToStore() {
      this.$vlf.getItem('redirect-path').then((path) => {
        Turbolinks.visit(path || '/')
      })
    },

    async confirmItems() {
      await this.cartService.confirmItems(
        this.selectedShippingMethodId,
        this.enableBonusDeduction
      )
      await this.$store.dispatch('ga4Operation', [
        ['event', 'td_checkout_step1', { td_position: '下一步' }]
      ])

      this._scrollToTop()
    }
  }
}
</script>
