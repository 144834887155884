<template lang="pug">
.vc-phone-verification-form
  .section-label
    | {{ pageTitleLocaleText('user', 'phone_verified', 'title') }}
  .form-section.has-sub-fields.profile-info
    b-field(
      :label="attributeLocaleText('user/profile', 'phone')"
      :type="errors.errorClassAt('phone')"
      :message="errors.get('phone')"
      :addons="false"
    )
      b-field(
        :type="errors.errorClassAt('verification_code') || user.phoneVerifiedType()"
        :message="errors.get('verification_code')"
      )
        b-field.phone-field(:type="errors.errorClassAt('phone')")
          b-input(
            type="tel"
            autocomplete="tel"
            name="tel"
            placeholder="e.g. 0910555888"
            v-model="form.profile.phone"
            @input="errors.clear('phone')"
          )
          .control(v-if="isSmsServiceEnabled")
            get-phone-verification-code-button(
              :form="form"
              :user="user"
              :show-verify-phone-button.sync="showVerifyPhoneButton"
            )
            span.button.is-static.phone-verified(
              v-if="user.profile.phone_verified"
            )
              span.icon
                i.fa.fa-check-circle
              span
                | {{ enumLocaleText('user/profile', 'phone_verified', user.profile.phone_verified) }}

      b-field.phone-verification(
        v-if="showVerifyPhoneButton"
        :label="actionLocaleText('enter_verification_code')"
      )
        verify-phone-button(
          :user="user"
          :errors="errors"
          :show-verify-phone-button.sync="showVerifyPhoneButton"
        )
</template>

<script>
import GetPhoneVerificationCodeButton from '../dashboard/profile/get-phone-verification-code-button.vue'
import VerifyPhoneButton from '../dashboard/profile/verify-phone-button.vue'
import UserForm from '../../../../shared/forms/user_form.js'

export default {
  components: {
    GetPhoneVerificationCodeButton,
    VerifyPhoneButton
  },

  // mixins: [],

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: new UserForm(this.user),
      showVerifyPhoneButton: false
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    siteFeatureConfigs() {
      return this.$store.getters['siteConfigs/allConfigs'].site_feature_configs
    },

    isSmsServiceEnabled() {
      return this.siteFeatureConfigs?.sms_service_configs?.enabled
    },

    isPhoneVerified() {
      return this.user.profile.phone_verified
    }
  },

  // created() {},

  async mounted() {
    if (!this.siteFeatureConfigs)
      await this.$store.dispatch('siteConfigs/getSiteFeatureConfigs')
  }

  // methods: {},
}
</script>
