<template lang="pug">
.vc-content-hot-news
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  content-card(
    v-for="content in hotNews"
    :content="content"
    :key="content.id"
  )
</template>

<script>
import ContentCard from './card.vue'

export default {
  components: {
    ContentCard
  },
  // mixins: [],
  props: {
    newsCount: {
      type: Number,
      required: false,
      default: () => {
        return 3
      }
    }
  },

  data() {
    return {
      hotNewsIds: null,
      isLoading: true
    }
  },

  computed: {
    hotNews() {
      if (this.hotNewsIds)
        return this.$store.getters['contents/all'].filter((news) =>
          this.hotNewsIds.includes(news.id)
        )
    },

    icon() {
      return fireIcon
    },

    listLocation() {
      return 'Hot News'
    }
  },

  created() {
    this.$store
      .dispatch('contents/all', {
        pageNumber: 1,
        pageSize: this.newsCount,
        replace: true,
        by_default_usages: ['news'],
        sort: '-published_at'
      })
      .then((response) => {
        this.hotNewsIds = response.data.data.map((news) => news.id)
        this.isLoading = false
      })
  }
  // mounted() {},
  // methods: {}
}
</script>
