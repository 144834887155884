<template lang="pug">
.vc-product-quantity-picker(:class="{ disabled: isConfirmed }")
  //- .label {{ actionLocaleText('set_quantity') }}

  .picker-unit
    .option-button.minus(
      @click="onClick($event, decreaseQuantity)"
      @mousedown="onStartLongPress($event, decreaseQuantity)"
      @touchstart.prevent="onStartLongPress($event, decreaseQuantity)"
      @mouseup="onStopLongPress($event, decreaseQuantity)"
      @mouseleave="onStopLongPress($event, decreaseQuantity)"
      @touchend="onStopLongPress($event, decreaseQuantity)"
      @touchcancel="onStopLongPress($event, decreaseQuantity)"
    )
      .icon
        i.fa.fa-minus
    .quantity-display
      span.number {{ quantity }}
    .option-button.plus(
      @click="onClick($event, increaseQuantity)"
      @mousedown="onStartLongPress($event, increaseQuantity)"
      @touchstart.prevent="onStartLongPress($event, increaseQuantity)"
      @mouseup="onStopLongPress($event, increaseQuantity)"
      @mouseleave="onStopLongPress($event, increaseQuantity)"
      @touchend="onStopLongPress($event, increaseQuantity)"
      @touchcancel="onStopLongPress($event, increaseQuantity)"
    )
      .icon
        i.fa.fa-plus
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    quantity: {
      type: Number,
      required: true
    },

    currentStock: {
      type: Number,
      required: true
    },

    currentVariant: {
      type: Object,
      required: true
    },

    currentOrderItem: {
      type: Object,
      required: false
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },

    isAllowBackorder: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },

    // 長押多久開始連續攻擊，預設半秒
    longPressThrottle: {
      type: Number,
      required: false,
      default: () => {
        return 500
      }
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    isQuantityMeetCounterStop() {
      return this.quantity >= this.currentStock
    },

    isQuantityMeetMinPurchaseQuantity() {
      return this.quantity === this.currentVariant.min_purchase_quantity
    },

    isQuantityMeetMaxPurchaseQuantity() {
      if (this.currentVariant.max_purchase_quantity === 0) return false

      if (this.currentOrderItem === undefined) {
        return this.quantity >= this.currentVariant.max_purchase_quantity
      } else {
        return (
          this.currentOrderItem.quantity + this.quantity >=
          this.currentVariant.max_purchase_quantity
        )
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    onClick(event, callback) {
      if (event.detail !== 0 || event.type === 'click') return

      callback()
    },

    onStartLongPress(event, callback) {
      if (event.button !== 0 && event.type !== 'touchstart') return

      this._$intervalTime = new Date()
      this._clearInterval()
      this._$timerRef = setTimeout(() => {
        this._$intervalRef = setInterval(() => {
          callback()
        }, 88)
      }, this.longPressThrottle)
    },

    onStopLongPress(event, callback) {
      if (!this._$timerRef) return

      if (!this._$intervalRef) callback()
      this._clearInterval()
      this._clearTimer()
    },

    _clearTimer() {
      clearTimeout(this._$timerRef)
      this._$timerRef = null
    },

    _clearInterval() {
      clearInterval(this._$intervalRef)
      this._$intervalRef = null
    },

    increaseQuantity() {
      if (this.isConfirmed) return
      if (this.isQuantityMeetCounterStop && !this.isAllowBackorder) return
      if (this.isQuantityMeetMaxPurchaseQuantity)
        return this.$store.dispatch('addFlashMessage', [
          'notice',
          `${this.messageLocaleText('failure.max_purchase_quantity_failure')} ${
            this.currentVariant.max_purchase_quantity
          }`
        ])

      this.$emit('update:quantity', this.quantity + 1)
    },

    decreaseQuantity() {
      if (this.isConfirmed) return
      if (
        this.isQuantityMeetMinPurchaseQuantity &&
        this.currentOrderItem === undefined
      )
        return this.$store.dispatch('addFlashMessage', [
          'notice',
          `${this.messageLocaleText('failure.min_purchase_quantity_failure')} ${
            this.currentVariant.min_purchase_quantity
          }`
        ])
      if (this.quantity === 1) return

      this.$emit('update:quantity', this.quantity - 1)
    }
  }
}
</script>
