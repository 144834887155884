import ProductCollectService from '../../../shared/services/product_collect_service.js'
import RegisterBanner from '../components/register/banner.vue'
import GetPhoneVerificationCodeButton from '../components/dashboard/profile/get-phone-verification-code-button.vue'
import VerifyPhoneButton from '../components/register/verify-phone-button.vue'
import User from '../../../shared/resource_models/user'
import Form from 'odd-form_object'

export default {
  name: 'register-container',

  components: {
    RegisterBanner,
    GetPhoneVerificationCodeButton,
    VerifyPhoneButton
  },

  // mixins: [],

  props: {
    user: {
      type: Object,
      required: false,
      default: () => {
        return new User({
          profile: {
            id: undefined,
            phone: null
          }
        })
      }
    }
  },

  data() {
    return {
      policyAgreed: false,
      form: new Form(this.user),
      showVerifyPhoneButton: false,
      phoneVerified: false,
      testMode: false
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    },

    signUpDisabled() {
      return !this.policyAgreed
    },

    genders() {
      return ['male', 'female']
    }
  },

  // created() {},

  mounted() {
    const urlParams = new URLSearchParams(window.location.search)

    if (
      process.env.RAILS_ENV !== 'production' &&
      Boolean(urlParams.get('letmetest'))
    ) {
      this.testMode = true
      this.phoneVerified = true
    }

    this.$vlf
      .getItem(ProductCollectService.collectionKey())
      .then((localCollections) => {
        document.getElementById('local_collections').value =
          localCollections.join(',')
      })
  },

  methods: {
    sendGa4Event(eventName, eventValue) {
      this.$store.dispatch('ga4Operation', [
        ['event', eventName, { td_position: eventValue }]
      ])
    },

    sendGa4LoginEvent(e, eventName) {
      const target = e.target.closest('.button')
      const serviceType = target.dataset.serviceType
      this.$store.dispatch('ga4Operation', [
        ['event', eventName, { td_position: serviceType }]
      ])
    },

    sendGa4TrackingEvent(e) {
      const form = e.target.closest('form')
      const email = form.querySelector('input[name="user[email]"]').value
      const gender = form.querySelector('[name="user[gender]"]').value

      if (!Boolean(email) || !Boolean(gender)) return

      this.$store.dispatch('ga4Operation', [
        ['event', 'td_register_email', { td_position: email }],
        ['event', 'td_register_gender', { td_position: gender }],
        ['event', 'td_register_submit', { td_position: '註冊送出' }]
      ])
    }
  }
}
