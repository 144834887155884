const itemComposer = (item) => {
  return {
    item_id: item.sku,
    item_name: item.name,
    item_brand: item.brand_name,
    price: item.consumer_price / 100
  }
}
const cartItemComposer = (item) => {
  return {
    item_id: item.sku,
    item_name: item.product_name,
    item_variant: item.name,
    item_brand: item.brand_name,
    price: item.consumer_price / 100
  }
}
const checkoutFlowComposer = (params) => {
  if (
    !Boolean(params['items']) ||
    !Boolean(params['variants']) ||
    !Boolean(params['value'])
  )
    throw new Error('no key `data` or `variants` or `value` in params')
  const items = params['items']
  const variants = params['variants']
  const currency = params['currency'] || 'TWD'
  const value = params['value'] || 0
  return {
    currency,
    value,
    items: items.map((item, index) => {
      return {
        ...cartItemComposer(
          variants.find((variant) => variant.id === item.variant_id)
        ),
        index,
        quantity: item.quantity
      }
    })
  }
}

const eventsComposer = {
  view_item_list: (params) => {
    if (!Boolean(params['items'])) throw new Error('no key `items` in params')
    const item_list_name = params['item_list_name'] || 'not set'

    return {
      item_list_name,
      items: params['items'].map((item, index) => {
        return { ...itemComposer(item), index, item_list_name }
      })
    }
  },

  add_to_wishlist: (params) => {
    if (!Boolean(params['item'])) throw new Error('no key `item` in params')
    const item = params['item']
    const quantity = params['quantity'] || 1
    const currency = params['currency'] || 'TWD'

    return {
      currency,
      value: item.consumer_price / 100,
      items: [{ ...itemComposer(item), quantity }]
    }
  },

  add_to_cart: (params) => {
    if (!Boolean(params['item'])) throw new Error('no key `item` in params')
    const item = params['item']
    const quantity = params['quantity'] || 1
    const currency = params['currency'] || 'TWD'

    return {
      currency,
      value: (item.consumer_price / 100) * quantity,
      items: [{ ...cartItemComposer(item), quantity }]
    }
  },

  remove_from_cart: (params) => {
    if (!Boolean(params['item'])) throw new Error('no key `item` in params')
    const item = params['item']
    const currency = params['currency'] || 'TWD'
    const quantity = params['quantity'] || 1

    return {
      currency,
      value: (item.consumer_price / 100) * quantity,
      items: [{ ...cartItemComposer(item), quantity }]
    }
  },

  begin_checkout: (params) => {
    return checkoutFlowComposer(params)
  },
  add_shipping_info: (params) => {
    return {
      ...checkoutFlowComposer(params),
      shipping_tier: params['shipping_tier']
    }
  },
  refund: (params) => {
    if (
      !Boolean(params['order']) ||
      !Boolean(params['items']) ||
      !Boolean(params['variants']) ||
      !Boolean(params['shipmentItems'])
    )
      throw new Error(
        'no key `order` or `items` or `variants` or `shipmentItems` in params'
      )
    const currency = params['currency'] || 'TWD'
    const order = params['order']
    const items = params['items']
    const variants = params['variants']
    const shipmentItems = params['shipmentItems']
    const variantFinder = (item) => {
      const shipmentItem = shipmentItems.find(
        (shipmentItem) =>
          String(shipmentItem.id) === String(item.shipment_item_id)
      )
      return variants.find(
        (variant) => String(variant.id) === String(shipmentItem.variant_id)
      )
    }

    return {
      currency,
      transaction_id: order.number,
      value: items.reduce((acc, item) => {
        const variant = variantFinder(item)

        return acc + (variant.consumer_price / 100) * item.apply_quantity
      }, 0),
      items: items.map((item, index) => {
        const variant = variantFinder(item)
        return {
          ...cartItemComposer(variant),
          quantity: item.apply_quantity,
          index
        }
      })
    }
  }
}

/**
 ,* generate, GA4 eve,nt parameter that used in `ga4Operation` vuex action
 */
const generateGA4Events = (
  eventName: string,
  params: { [key: string]: object[] | object | string | number }
) => {
  const isGa4Enabled = Boolean(document.querySelector('[data-ga4-enabled]'))

  if (isGa4Enabled) {
    return ['event', eventName, eventsComposer[eventName](params)]
  } else {
    return [null, null, null]
  }
}

export { generateGA4Events }
export default generateGA4Events
