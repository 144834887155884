import productPropsMixin from './product_props_mixin.js'
import CollectButton from '../product/collect-button.vue'
import PurchaseButton from '../product/purchase-button.vue'

export default {
  components: {
    CollectButton,
    PurchaseButton
  },

  mixins: [productPropsMixin],

  props: {
    entryPath: {
      type: String,
      required: false
    },

    idPrefix: {
      type: String,
      required: false,
      default: () => {
        return 'product'
      }
    }
  },

  // data() {
  //   return {}
  // },
  computed: {
    // onsaleIndicatorIcon() {
    //   return priceTagIcon
    // },

    /**
     * @returns {boolean}
     */
    shouldBlurOutCover() {
      if (this.entryPath?.includes('/adults-only')) return false

      return !!this.product.is_age_restricted
    },

    productLink() {
      return `/products/${this.product.slug || this.product.id}`
    },

    /**
     * @returns {string}
     */
    anchorId() {
      return `${this.idPrefix}-${this.product.id}`
    },

    /**
     * `locationToEventNamesDict` is a dictionary to map the location of the
     * product card, to the custom event name of GA4. if `listLocation` start
     * with the keys of `locationToEventNamesDict`, return the value of the key.
     * otherwise, return undefined.
     * @returns {string | undefined}
     */
    customGa4EventName() {
      const locationToEventNamesDict = {
        'Hot Products': 'td_hot_items',
        'Select Goods - ': 'td_category_item',
        'Products Page': 'td_product',
        'Category - ': 'td_product'
      }
      const matchedLocation = Object.keys(locationToEventNamesDict).find(
        (location) => this.listLocation.startsWith(location)
      )

      return locationToEventNamesDict[matchedLocation]
    }
  },
  // created() {},
  mounted() {
    this.sendGaImpressionEvent()
  },

  methods: {
    sendGaImpressionEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addImpression',
            {
              id: this.product.sku,
              name: this.product.name,
              brand: this.product.brand_name,
              list: this.listLocation,
              position: this.index + 1,
              price: this.toMoney(this.product.consumer_price, {
                isExchange: false
              }).amount
            }
          ]
        ])
        .then((_) => {
          this.$emit('ga-impressed')
        })
    },

    async sendGaClickEvent() {
      if (Boolean(this.customGa4EventName)) {
        await this.$store.dispatch('ga4Operation', [
          [
            'event',
            this.customGa4EventName,
            {
              td_position: `${this.product.name}`
            }
          ]
        ])
      }
      await this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.product.sku,
            name: this.product.name,
            brand: this.product.brand_name,
            position: this.index + 1,
            price: this.toMoney(this.product.consumer_price, {
              isExchange: false
            }).amount
          }
        ],
        [
          'ec:setAction',
          'click',
          {
            list: this.listLocation
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'UX',
            eventAction: 'click',
            eventLabel: this.listLocation,
            transport: 'beacon'
          }
        ]
      ])
    },

    /**
     * store curren URL and clicked card ID into indexDB.
     * @param _event click event
     * @returns {Promise<void>}
     */
    async clickHandler(_event) {
      //event.preventDefault()
      const prevPageInfo = {
        url: window.location.href,
        leaveFrom: this.anchorId
      }

      // remember user leave from which point
      await this.$vlf.setItem('prevPage', prevPageInfo)
      await this.sendGaClickEvent()
    }
  }
}
