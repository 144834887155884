<template lang="pug">
.vc-common-mobile-category-menu
  b-loading(
    :active="isLoading"
    :is-full-page="false"
  )
  transition(
    enter-active-class="animated slideInLeft"
    leave-active-class="animated slideOutLeft"
  )
    ul.mobile-menu-items
      li.mobile-menu-item(
        v-for="topLevelCategory in topLevelCategories"
        :key="topLevelCategory.id"
      )
        a.link(
          href="#"
          :class="{ 'is-active': currentCategory && currentCategory.id === topLevelCategory.id }"
          @click.prevent="selectCategory(topLevelCategory)"
        )
          span.text {{ topLevelCategory.name }}
          .icon(v-if="subCategories(topLevelCategory).length > 0")
            i.fa.fa-chevron-right
        transition(
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        )
          ul.sub-categories(
            v-if="subCategories(topLevelCategory).length > 0 && currentCategory && currentCategory.id === topLevelCategory.id"
          )
            li.sub-category-unit(
              :class="{ 'is-active': isActiveTypeEqSelectedType && currentCategory && currentCategory.id === selectedCategoryIdFromUrl }"
            )
              a.link.selected-top-level-category(
                href="#"
                @click.prevent="linkTo(topLevelCategory)"
              )
                span.name {{ copyLocaleText('all_x', { x: topLevelCategory.name }) }}
            li.sub-category-unit(
              v-for="subCategory in currentSubCategories"
              :key="subCategory.id"
              :class="{ 'is-active': selectedCategory && selectedCategory.id === subCategory.id }"
            )
              a.link(
                href="#"
                @click.prevent="linkTo(subCategory)"
              )
                span.name {{ subCategory.name }}
</template>

<script>
import queryString from 'query-string'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      currentCategory: undefined,
      currentSubCategory: undefined
    }
  },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    isCategoriesSelectorActive() {
      return this.mobileMenuStates['isCategoriesSelectorActive']
    },

    selectedCategory() {
      return this.currentSubCategory || this.currentCategory
    },

    selectedCategoryIdFromUrl() {
      return queryString.parse(window.location.search)['category_id']
    },

    isActiveTypeEqSelectedType() {
      return (
        this.mobileMenuStates['activatedCategoryType'] ===
        this.mobileMenuStates['selectedCategoryType']
      )
    },

    childOfRootCategory() {
      return this.$store.getters['categorySystems/categoriesByUsage'](
        this.mobileMenuStates['activatedCategoryType']
      )[0]
    },

    topLevelCategories() {
      let childOfRootCategory = this.childOfRootCategory

      if (this.mobileMenuStates['activatedCategoryType'] === 'product')
        return this.$store.getters['productCategories/allTopLevel'].filter(
          (category) => !category.is_age_restricted
        )
      if (this.mobileMenuStates['activatedCategoryType'] === 'adults_only')
        return this.$store.getters['productCategories/allTopLevel'].filter(
          (category) => category.is_age_restricted
        )
      if (!childOfRootCategory) return []

      return this.$store.getters['categorySystems/allCategories']({
        depth: this.depth
      }).filter(
        (category) => childOfRootCategory.id === String(category.parent_id)
      )
    },

    currentSubCategories() {
      if (!this.currentCategory) return []

      return this.subCategories(this.currentCategory)
    },

    isLoading() {
      if (
        ['product'].includes(this.mobileMenuStates['activatedCategoryType'])
      ) {
        return this.$store.getters['productCategories/isLoading']
      } else {
        return this.$store.getters['categorySystems/isLoading']
      }
    }
  },

  // created() {},

  mounted() {
    if (this.topLevelCategories.length > 0) return this.updateCategoryFromUrl()

    if (['product'].includes(this.mobileMenuStates['activatedCategoryType'])) {
      this.$store
        .dispatch('productCategories/all', {
          sort: 'position'
        })
        .then(() => this.updateCategoryFromUrl())
    } else {
      this.$store
        .dispatch(`categorySystems/fetchCategoriesByType`, {
          type: this.mobileMenuStates['activatedCategoryType'],
          sort: 'position',
          noReplace: true
        })
        .then(() => this.updateCategoryFromUrl())
    }
  },

  methods: {
    updateCategoryFromUrl() {
      if (!this.isActiveTypeEqSelectedType) return

      if (!this.selectedCategoryIdFromUrl) return

      this.currentCategory = this.topLevelCategories.find(
        (category) => category.id === this.selectedCategoryIdFromUrl
      )

      if (!this.currentCategory) {
        if (
          ['product', 'adults_only'].includes(
            this.mobileMenuStates['activatedCategoryType']
          )
        ) {
          this.currentSubCategory = this.$store.getters[
            'productCategories/all'
          ].find((category) => category.id === this.selectedCategoryIdFromUrl)
        } else {
          this.currentSubCategory = this.$store.getters[
            'categorySystems/allCategories'
          ]({
            depth: this.depth + 1
          }).find((category) => category.id === this.selectedCategoryIdFromUrl)
        }

        this.currentCategory = this.topLevelCategories.find(
          (category) =>
            category.id === String(this.currentSubCategory.parent_id)
        )
      }
    },

    selectCategory(category) {
      if (this.subCategories(category).length === 0)
        return this.linkTo(category)

      if (this.currentCategory && this.currentCategory.id === category.id) {
        this.currentCategory = undefined
      } else {
        this.currentCategory = category
      }
    },

    selectSubCategory(category) {
      this.currentSubCategory = category
    },

    subCategories(currentCategory) {
      if (
        ['product', 'adults_only'].includes(
          this.mobileMenuStates['activatedCategoryType']
        )
      ) {
        return this.$store.getters['productCategories/all'].filter(
          (category) => String(category.parent_id) === currentCategory.id
        )
      } else {
        return this.$store.getters['categorySystems/allCategories']().filter(
          (category) => String(category.parent_id) === currentCategory.id
        )
      }
    },

    async linkTo(category) {
      let page = this.mobileMenuStates['activatedCategoryType']
      if (
        ['sales_event', 'adults_only'].includes(
          this.mobileMenuStates['activatedCategoryType']
        )
      )
        page = page.replace('_', '-')
      if (this.mobileMenuStates['activatedCategoryType'] !== 'adults_only')
        page += 's'

      await this.$store.dispatch('ga4Operation', [
        [
          'event',
          'td_category',
          {
            td_position:
              this.subCategories(category).length === 0
                ? category.name
                : `${this.currentCategory.name}_${category.name}`
          }
        ]
      ])

      Turbolinks.visit(`/${page}?category_id=${category.id}`)
    }
  }
}
</script>
