import productPropsMixin from './product_props_mixin.js'
import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import QuantityPicker from '../product/quantity-picker.vue'
import DeliveryWeekdaysSelector from '@applicationComponents/product/delivery-weekdays-selector.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    QuantityPicker,
    DeliveryWeekdaysSelector
  },

  mixins: [productPropsMixin],

  props: {
    preSelectedWeekdays: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      selectedVariantId: null,
      quantity: 1,
      cartService: null,
      isProcessing: false,
      selectedDeliveryWeekdays: []
    }
  },

  computed: {
    isSubscription() {
      return this.product.is_subscription
    },

    isVariantsLoaded() {
      return typeof this.selectedVariantId === 'string'
    },

    isPurchasable() {
      return (
        this.selectedVariant &&
        (this.selectedVariant.stock > 0 ||
          this.selectedVariant.is_allow_backorder)
      )
    },

    subDiscountedTotal() {
      return this.toMoney(this.selectedVariant.original_price).multiply(
        this.quantity
      )
    },

    subTotal() {
      return this.toMoney(this.selectedVariant.consumer_price).multiply(
        this.quantity
      )
    },

    masterVariant() {
      return this.variants.find((variant) => variant.is_master === true)
    },

    currentStock() {
      return this.selectedVariant.stock || 0
    },

    cartItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']()
    }
  },

  mounted() {
    // console.log('please add `this._initializeCartService()` to mounted hook.')
  },

  methods: {
    deliveryWeekdaySelectedHandler(weekdays) {
      this.selectedDeliveryWeekdays = weekdays
    },

    _initializeCartService() {
      this.cartService = new ShoppingCartService(this.$vlf, this.$store)
    },

    addToCart() {
      console.log('please implement method `#addToCart` in component.')
    },

    buyNow() {
      console.log('please implement method `#buyNow` in component.')
    },

    _callAddToCartMethod() {
      this.isProcessing = true
      let requestBody = this.requestBody

      Object.assign(requestBody.data.attributes, {
        selected_delivery_weekdays: this.selectedDeliveryWeekdays
      })

      return this.cartService
        .addItemToCart(requestBody)
        .then(() => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('product_added_to_cart_successfully')
          ])
        })
        .then((_) => {
          return this._tracingAddToCartEvent()
        })
    },

    _tracingAddToCartEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addProduct',
            {
              id: this.selectedVariant.sku,
              name: this.selectedVariant.product_name,
              brand: this.product.brand_name,
              variant: this.selectedVariant.name,
              position: this.index + 1,
              price: this.toMoney(this.selectedVariant.consumer_price, {
                isExchange: false
              }).amount,
              quantity: this.quantity
            }
          ],
          [
            'ec:setAction',
            'add',
            {
              list: this.listLocation
            }
          ],
          [
            'send',
            {
              hitType: 'event',
              eventCategory: 'Ecommerce',
              eventAction: 'Add to Cart'
            }
          ]
        ])
        .then((_) => {
          return this.$store.dispatch('ga4Operation', [
            generateGA4Events('add_to_cart', {
              item: this.selectedVariant,
              quantity: this.quantity
            }),
            [
              'event',
              'td_add_to_cart',
              {
                td_position: this.selectedVariant.product_name
              }
            ]
          ])
        })
        .then((_) => {
          return this.$store.dispatch('pixelOperation', [
            [
              'track',
              'AddToCart',
              {
                content_ids: this.selectedVariant.sku,
                content_name: this.selectedVariant.product_name,
                content_type: 'Product',
                currency: 'TWD',
                value: this.toMoney(this.selectedVariant.consumer_price, {
                  isExchange: false
                }).amount,
                quantity: this.quantity
              }
            ]
          ])
        })
        .then((_) => {
          if (typeof gtag === 'undefined') return
          gtag('event', 'conversion', {
            send_to: 'AW-11016936351/7ksqCM2zgYEYEJ-3pIUp',
            value: this.toMoney(this.selectedVariant.consumer_price, {
              isExchange: false
            }).amount,
            currency: 'TWD'
          })
        })
    }
  }
}
