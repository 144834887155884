<template lang="pug">
.vc-product-search-box.tool
  input.input(
    v-model="searchText"
    :placeholder="messageLocaleText('extra_product_name_or_key_word')"
    @keydown.enter="searchOptionUpdated"
    @compositionend="composing = false"
    @compositionstart="composing = true"
  )
  a.icon(@click.prevent="searchOptionUpdated")
    i.fa.fa-search
</template>

<script>
import queryString from 'query-string'
import URIConvertService from '@services/uri_convert_service'

export default {
  // components: {},
  // mixins: [],
  // props: {},

  data() {
    return {
      searchText: null,
      composing: false
    }
  },

  // computed: {},
  // created() {},
  mounted() {
    let currentQueryString = queryString.parse(
      URIConvertService.decode(window.location.search),
      { decode: false }
    )

    this.searchText =
      currentQueryString[
        'q[variants_with_master_sku_or_brand_name_or_name_cont]'
      ]

    if (this.searchText) this.searchOptionUpdated()

    this.$emit('update:search-options-ready', true)
  },

  methods: {
    async searchOptionUpdated() {
      if (!this.composing) {
        await this.$store.dispatch('pixelOperation', [['track', 'Search']])
        await this.$store.dispatch('ga4Operation', [
          ['event', 'td_category_search', { td_position: this.searchText }]
        ])

        this.$emit('update:search-options', this.searchText)
      }
    }
  }
}
</script>
