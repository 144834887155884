<template lang="pug">
.vc-order-shipment-item-unit
  .group
    .group.option-and-thumb
      .checkbox-wrapper(v-if="isEditable")
        b-checkbox(
          v-model="isItemSelected"
          type="is-warning"
          @input="emitEvent"
          :disabled="!rmaApplicable"
        )
      .product-thumb
        img.img(:src="orderItem.coverImageThumb()")
    .group.name-and-unit-price
      .product-name
        .name {{ variant.product_name }}
        .variant(v-if="variant.name") {{ variant.name }}
        .state-tag
          span.tag(:class="stateType") {{ aasmStateLocaleText('order/shipment_item', 'state', item.state) }}
          span.tag.is-warning(v-if="item.is_rma_pending") {{ attributeLocaleText('order/shipment_item', 'is_rma_pending') }}
          a.tag.is-light(
            v-if="item.return_authorization_number"
            :href="returnAuthorizationPath"
          )
            .icon
              i.fa.fa-search
            span {{ modelNameLocaleText('return_authorization') }}
      .unit-price
        label.label {{ attributeLocaleText('order/item', 'price') }}
        .price(data-currency="TWD")
          span(:class="{ gift: isGiftItem }") {{ toMoney(orderItem.price, { isExchange: false }).format() }}
          span.gift-tag(v-if="isGiftItem") {{ copyLocaleText('gift') }}
  .group
    .quantity-config
      quantity-picker(
        :quantity.sync="quantity"
        :current-stock="item.quantity"
        :current-variant="variant"
        :is-confirmed="!rmaApplicable || !isEditable"
        :is-allow-backorder="variant.is_allow_backorder"
      )
</template>

<script>
import queryString from 'query-string'
import QuantityPicker from '../product/quantity-picker.vue'

export default {
  components: {
    QuantityPicker
  },
  // mixins: [],
  props: {
    item: {
      type: Object,
      required: true
    },

    form: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      isItemSelected: false,
      quantity: 0
    }
  },

  computed: {
    rmaApplicable() {
      return this.item.state !== 'returned' && !this.item.is_rma_pending
    },

    isEditable() {
      // if this.form is an object that pass in via props
      return !!this.form
    },

    stateType() {
      return {
        'is-info': this.item.state === 'on_hand',
        'is-warning': this.item.state === 'backordered',
        'is-success': this.item.state === 'shipped',
        'is-danger': this.item.state === 'returned'
      }
    },

    orderItem() {
      return this.$store.getters['orderItems/find'](this.item.item_id)
    },

    variant() {
      return this.$store.getters['productVariants/find'](this.item.variant_id)
    },

    variantThumb() {
      if (!this.variant.cover)
        return 'https://via.placeholder.com/128x128?text=No+Image'

      return this.variant.cover.thumb.url
    },

    returnItemObject() {
      let result = {
        shipment_item_id: this.item.id,
        name: `${this.variant.product_name}`,
        image: this.variantThumb,
        apply_quantity: this.quantity,
        unit_price: this.toMoney(this.orderItem.price, { isExchange: false })
          .amount
      }

      if (this.variant.name) result['name'] += ` - ${this.variant.name}`

      return result
    },

    returnAuthorizationPath() {
      if (window.location.pathname.includes('/guest/orders/')) {
        const params = queryString.parse(window.location.search)
        return `/guest/return_authorizations/${this.item.return_authorization_number}?token=${params.token}`
      } else {
        return `/user/return_authorizations/${this.item.return_authorization_number}`
      }
    },

    isGiftItem() {
      return this.orderItem.use_type === 'gift'
    }
  },

  watch: {
    quantity: {
      handler(newValue) {
        if (!this.isEditable) return

        let returnItem = this.form.items.find(
          (item) => item.shipment_item_id === this.item.id
        )

        if (returnItem) returnItem.apply_quantity = newValue
      }
    }
  },

  // created() {},
  mounted() {
    this.quantity = this.item.quantity
    if (
      this.isEditable &&
      this.form.items.find((item) => item.shipment_item_id === this.item.id)
    )
      this.isItemSelected = true
  },

  methods: {
    changeCheckboxValueTo(boolean) {
      if (this.isItemSelected === boolean) return

      this.isItemSelected = boolean
      this.emitEvent()
    },

    emitEvent() {
      if (this.isItemSelected) {
        this.addItem()
      } else {
        this.removeItem()
      }
    },

    addItem() {
      this.form.items.push(this.returnItemObject)
    },

    removeItem() {
      const index = this.form.items.findIndex(
        (item) => item.shipment_item_id === this.item.id
      )

      this.form.items.splice(index, 1)
    }
  }
}
</script>
