<template lang="pug">
.vc-register-verify-phone-button
  b-field.has-addons(
    :type="errors.errorClassAt('verification_code') || phoneVerifiedType"
  )
    b-input(
      type="text"
      :placeholder="`${actionLocaleText('enter_verification_code')} ex: 123456`"
      v-model="verificationCode"
      @input="errors.clear('verification_code')"
      expanded
    )

    template(v-if="showVerifyButton")
      .control
        .button.is-static
          countdown(
            :time="180000"
            :interval="1000"
            ref="countdown"
            @end="countdownEndedHandler"
          )
            template(slot-scope="props") {{ props.minutes }}：{{ props.seconds }}
      .control
        .button.is-odd(
          @click="verifyPhone"
          :class="{ 'is-loading': isLoading }"
        )
          span {{ actionLocaleText('confirm') }}
</template>

<script>
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    Countdown
  },

  // mixins: [],
  props: {
    errors: {
      type: Object,
      required: true
    },

    showVerifyPhoneButton: {
      type: Boolean,
      required: true
    },

    form: {
      type: Object,
      required: true
    },

    user: {
      type: Object,
      required: true
    },

    phoneVerified: {
      type: Boolean,
      required: true,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      verificationCode: ''
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    showVerifyButton() {
      if (this.phoneVerified) return false

      return this.showVerifyPhoneButton
    },

    phoneVerifiedType() {
      return {
        'is-success': this.phoneVerified
      }
    }
  },

  // created() {},
  // mounted() {},

  methods: {
    async verifyPhone() {
      await this.$store.dispatch('users/verifyRegisteredPhone', {
        model: this.user,
        phone: this.form.profile.phone,
        verificationCode: this.verificationCode
      })

      this.$emit('update:phoneVerified', true)
    },

    countdownEndedHandler() {
      this.$emit('update:showVerifyPhoneButton', false)
    }
  }
}
</script>
