<template lang="pug">
.vc-promoter-event-product-unit(
  :class="{ 'is-age-restricted': shouldBlurOutCover }"
  :id="anchorId"
)
  .inner
    .product-image
      a.product-link(@click="visitProductPage")
        img.image(v-lazy="product.coverImageThumb()")
        .age-restricted-indicator
          .age-restricted-message
            i.fa.fa-ban
            .text R-18
      .indicator(v-if="hasDiscountRate")
        | {{ discountRate }}
        | {{ attributeLocaleText('product', 'discount_unit') }}
      .indicator.is-sold-out(v-if="product.is_sold_out")
        | {{ copyLocaleText('stock_status.no_stock') }}

    .info-wrapper
      .product-name
        .name {{ product.name }}
      .price-info
        .price-type
          span.price(:data-currency="currentCurrency") {{ toMoney(product.original_price).format() }}
        .price-type
          span.price(:data-currency="currentCurrency") {{ variantShipPrice.format() }}

      //- .description
        | {{ product.card_description }}

      .options-wrapper
        purchase-button(
          :product="product"
          :promoter-share="promoterShare"
          :index="index"
          :list-location="listLocation"
        )
</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'
import promoterEventVariantMixin from '../mixins/promoter_event_variant_mixin.js'
import PurchaseButton from './purchase-button.vue'

export default {
  components: {
    PurchaseButton
  },

  mixins: [productCardMixin, promoterEventVariantMixin],

  // props: {},

  // data() {},

  computed: {
    productLink() {
      const basicPath = this.promoterShare.storeFrontUrl()

      return `${basicPath}/products/${this.product.slug || this.product.id}`
    },

    _variantShipCompareTarget() {
      return this.product
    },

    consumerPriceLabeltext() {
      return this.hasDiscountRate
        ? this.copyLocaleText('discount_price')
        : this.copyLocaleText('msrp')
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    visitProductPage(event) {
      this.clickHandler(event)

      Turbolinks.visit(this.productLink)
    },

    sendGaImpressionEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addImpression',
            {
              id: this.product.sku,
              name: this.product.name,
              brand: this.product.brand_name,
              list: this.listLocation,
              position: this.index + 1,
              price: this.variantShipPrice.amount
            }
          ]
        ])
        .then((_) => {
          this.$emit('ga-impressed')
        })
    },

    sendGaClickEvent() {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.product.sku,
            name: this.product.name,
            brand: this.product.brand_name,
            position: this.index + 1,
            price: this.variantShipPrice.amount
          }
        ],
        [
          'ec:setAction',
          'click',
          {
            list: this.listLocation
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'UX',
            eventAction: 'click',
            eventLabel: this.listLocation,
            transport: 'beacon'
          }
        ]
      ])
    }
  }
}
</script>
