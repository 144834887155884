<template lang="pug">
.vc-blog-card(:class="{ 'is-age-restricted': !!this.blog.is_age_restricted }")
  .inner
    a.blog-image(
      :href="blogLink"
      @click="sendClickEvent"
    )
      img.image(v-lazy="coverSrc")
      .age-restricted-indicator
        .age-restricted-message
          i.fa.fa-ban
          .text R-18
    .blog-info
      .blog-time
        | {{ dateTimeLocaleText(blog.published_at, (format = 'LL')) }}
      a.blog-title
        h4.inner-wrapper(:href="blogLink")
          | {{ blog.title }}
      .blog-description
        .description {{ blog.description() }}
      .options-wrapper
        .option-button
          a.button.is-default(:href="blogLink")
            span.text {{ copyLocaleText('read_blog') }}
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 431

export default {
  // components: {},
  // mixins: [],
  props: {
    blog: {
      type: Object,
      required: true
    },
    categoryName: {
      type: String,
      required: false
    },
    position: {
      type: [String, null],
      required: false,
      default: null
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    blogLink() {
      return `/blogs/${this.blog.id}/${this.blog.slug}`
    },

    coverSrc() {
      return this.isMobile
        ? this.blog.mobile_cover.url || this.blog.cover.url
        : this.blog.cover.url
    },

    customGa4EventName() {
      switch (this.position) {
        case 'home':
          return 'td_top_article'
        case 'latest':
          return 'td_newest_article'
        case 'related':
          return 'td_related_article'
        default:
          return 'td_article'
      }
    },

    customGa4EventValue() {
      if (Boolean(this.categoryName)) {
        return `${this.categoryName}_${this.blog.title}`
      } else {
        return this.blog.title
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    sendClickEvent() {
      this.$store.dispatch('ga4Operation', [
        [
          'event',
          this.customGa4EventName,
          {
            td_position: this.customGa4EventValue
          }
        ]
      ])
    }
  }
}
</script>
