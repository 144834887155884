<template lang="pug">
.vc-order-comment-button.button.is-outlined.is-default(
  @click="openOrderCommentForm"
)
  .icon
    i.fa(:class="iconType")
  span.text {{ actionLocaleText('contact_customer_service') }}
</template>

<script>
import CommentForm from './comment-form.vue'

export default {
  components: {
    CommentForm
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    jwtToken: {
      type: String,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    iconType() {
      return {
        'fa-comments-o': !this.order.has_new_reply,
        'fa-commenting animated infinite jello': this.order.has_new_reply
      }
    }
  },
  // created() {},
  mounted() {
    if (window.location.search.includes('show_comments=1'))
      this.openOrderCommentForm()
  },

  methods: {
    async openOrderCommentForm() {
      await this.$store.dispatch('ga4Operation', [
        ['event', 'td_contact', { td_position: '聯繫客服' }]
      ])
      this.$buefy.modal.open({
        parent: this,
        component: CommentForm,
        width: 320,
        props: {
          order: this.order,
          jwtToken: this.jwtToken
        }
      })
    }
  }
}
</script>
