import PhoneVerificationForm from '../../components/phone_verification/form.vue'

export default {
  name: 'user-phone-verification-container',

  components: {
    PhoneVerificationForm
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      redirectUrl: undefined
    }
  },

  computed: {
    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    }
  },

  watch: {
    currentUserData: {
      immediate: true,
      handler(newValue) {
        if (typeof newValue.id === 'string' && newValue.profile.phone_verified)
          Turbolinks.visit(this.redirectUrl || '/')
      }
    }
  },

  // created() {},

  async mounted() {
    this.redirectUrl = this.$el.dataset.redirectUrl
    if (!this.currentUserData.isDataLoaded())
      await this.$store.dispatch('users/find', this.currentUser.id)
  }

  // methods: {}
}
