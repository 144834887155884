<template lang="pug">
.vc-product-card(
  :class="{ 'is-age-restricted': shouldBlurOutCover }"
  :id="anchorId"
)
  .inner
    .product-image
      a.product-link(
        :href="productLink"
        @click="clickHandler"
      )
        img.image(v-lazy="product.coverImageThumb()")
        .age-restricted-indicator
          .age-restricted-message
            i.fa.fa-ban
            .text R-18
      .indicator(v-if="product.hasDiscount()")
        | {{ product.displayDiscountRate() }}
      .indicator.is-sold-out(v-if="product.is_sold_out")
        | {{ copyLocaleText('stock_status.no_stock') }}
      collect-button(:product="product")

    .info-wrapper
      .product-name
        .name {{ product.name }}
      .price-info
        //- //- if has no discount, this placeholder should hold up height.
        .price-type(v-if="product.hasDiscount()")
          span.price(:data-currency="currentCurrency") {{ toMoney(product.original_price).format() }}
        .price-type.placeholder(v-else)
          span.price &nbsp;
        .price-type
          span.price(:data-currency="currentCurrency") {{ toMoney(product.consumer_price).format() }}

      //- .description
        | {{ product.card_description }}

    .options-wrapper
      purchase-button(
        :product="product"
        :index="index"
        :list-location="listLocation"
      )
</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'

export default {
  // components: {},
  mixins: [productCardMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    consumerPriceLabeltext() {
      return this.product.hasDiscount()
        ? this.copyLocaleText('discount_price')
        : this.copyLocaleText('msrp')
    }
  }
  // created() {},
  // mounted() {}
  // methods: {}
}
</script>
