<template lang="pug">
.vc-blog-relevant-blogs
  .blog-section-header.-border-left
    h2.section-title.-justify-left {{ copyLocaleText('relevant_blog') }}
  .blog-section-body
    blog-card(
      v-for="blog in blogs"
      :key="blog.id"
      :blog="blog"
      position="related"
    )
</template>

<script>
import BlogCard from './card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    blog: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      blogIds: [],
      isLoading: true
    }
  },

  computed: {
    blogs() {
      return this.blogIds.map((id) =>
        this.$store.getters['promotionalArticles/find'](id)
      )
    }
  },

  // created() {},

  mounted() {
    this.$store
      .dispatch('promotionalArticles/all', {
        pageNumber: 1,
        pageSize: 6,
        sort: '-published_at',
        usage: 'blog',
        forSearch: true,
        search: {
          id_not_eq: this.blog.id,
          categories_id_in: this.blog.category_ids
        }
      })
      .then((response) => {
        this.blogIds = response.data.data.map((blog) => blog.id)
        this.isLoading = false
      })
  }
  // methods: {}
}
</script>
