<template lang="pug">
.vc-product-delivery-weekdays-selector(v-if="isSelectable")
  .title-wrapper
    .plan-title {{ deliveryPlan.shipping_plan_title }}
    .helper {{ this.messageLocaleText('please_select_delivery_weekdays') }}
  .buttons-wrapper
    b-checkbox-button(
      v-model="selectedDeliveryWeekdays"
      v-for="(day, index) in weekdays"
      :native-value="index"
      :key="index"
      size="is-normal"
      type="checked"
      @input="deliveryWeekdaySelectedHandler"
      v-if="availableDeliveryWeekday.includes(index)"
    )
      | {{ day }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    variant: {
      type: Object,
      required: true
    },

    preSelectedWeekdays: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      selectedDeliveryWeekdays: []
    }
  },

  watch: {
    variant: {
      handler() {
        this.resetWeekdays()
      }
    }
  },

  computed: {
    deliveryPlan() {
      return this.variant.bundle_preference?.delivery_plan || {}
    },

    isSelectable() {
      return (
        this.variant.is_bundle &&
        this.deliveryPlan.is_delivery_weekday_selectable
      )
    },

    availableDeliveryWeekday() {
      return this.deliveryPlan.available_delivery_weekday
    },

    weekdays() {
      return this.localeTextFor('date.abbr_day_names')
    }
  },
  // created() {},
  mounted() {
    this.selectedDeliveryWeekdays = this.preSelectedWeekdays
  },

  methods: {
    resetWeekdays() {
      this.selectedDeliveryWeekdays = []
    },

    deliveryWeekdaySelectedHandler(event) {
      if (event.length > 1) this.selectedDeliveryWeekdays.splice(0, 1)
      this.$emit('delivery-weekday-selected', this.selectedDeliveryWeekdays)
    }
  }
}
</script>
