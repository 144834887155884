export default {
  name: 'faq-page-container',

  // components: {},

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  // computed: {},

  // created() {},

  // mounted() {},

  methods: {
    sendGa4Event(e) {
      const target = e.currentTarget
      this.$store.dispatch('ga4Operation', [
        ['event', 'td_faq', { td_position: target.dataset.question }]
      ])
    }
  }
}
