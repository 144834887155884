<template lang="pug">
.vc-blog-top-blogs(v-if="blogIds.length > 0")
  .blog-section-header
    h2.section-title.-larger 置頂文章
  .blog-section-body
    .section
      .left
        blog-card.large.vertical(
          v-if="firstBlog"
          :key="firstBlog.id"
          :blog="firstBlog"
          section-title="置頂文章"
          position="home"
        )
      .right
        blog-card.small.horizontal(
          v-for="blog in sideBlogs"
          :key="blog.id"
          :blog="blog"
          section-title="置頂文章"
          position="home"
        )
</template>

<script>
import BlogCard from './card.vue'

export default {
  components: {
    BlogCard
  },
  // mixins: [],
  // props: {},
  data() {
    return {
      blogIds: []
    }
  },

  computed: {
    blogIndexSettings() {
      return this.$store.getters['siteConfigs/siteCustomDisplayConfig']
        .blog_index
    },

    blogs() {
      return this.blogIds.map((id) =>
        this.$store.getters['promotionalArticles/find'](id)
      )
    },

    firstBlog() {
      return this.blogs[0]
    },

    sideBlogs() {
      return this.blogs.slice(1, 4)
    }
  },

  mounted() {
    this.fetchBlogs()
  },

  methods: {
    fetchBlogs() {
      let config = this.blogIndexSettings
      if (!config.is_top_blog_show) return
      if (config.top_blog_ids.length == 0) return

      this.$store
        .dispatch('promotionalArticles/all', {
          usage: 'blog',
          forSearch: true,
          search: {
            id_in: this.blogIndexSettings.top_blog_ids
          }
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
        })
    }
  }
}
</script>
