<template lang="pug">
.vc-order-payment-method-selector(:class="{ 'is-confirmed': isConfirmed }")
  .selectable-method-unit(
    v-for="method in selectablePaymentMethods"
    v-if="isSelected(method) || !isConfirmed"
    :key="method.id"
    @click="selectPaymentMethod(method)"
    :class="{ 'is-selected': isSelected(method) }"
  )
    .select-button
    .selectable-method
      .icon
        i.fa(
          v-if="methodIconClass(method)"
          :class="methodIconClass(method)"
        )
        img(
          v-if="imageIcon(method)"
          :src="imageIcon(method)"
        )
      span {{ method.name }}
    .selectable-card(v-if="!isConfirmed && methodHasUserCreditCards(method)")
      span(v-if="defaultUserCreditCardForPaymentMethod(method)") {{ defaultUserCreditCardForPaymentMethod(method).fullCardNo() }}
      a.edit(@click.prevent.stop="clickEdit(method)") {{ attributeLocaleText('user/credit_card', 'edit') }}
</template>

<script>
import UserCreditCardList from './user-credit-card-list.vue'
import linePayImage from '@application/images/line-pay.png'

export default {
  // components: {},
  // mixins: [],
  props: {
    selectedPaymentMethodId: {
      type: [String, Number],
      required: false
    },

    selectedUserCreditCardId: {
      type: [String, Number],
      required: false
    },

    paymentMethods: {
      type: Array,
      required: true
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  // data() {
  //   return {}
  // },

  watch: {
    userCreditCards: {
      handler() {
        this._setUserCreditCardId()
      }
    },

    selectedPaymentMethodId: {
      handler() {
        this._setUserCreditCardId()
      }
    }
  },

  computed: {
    userCreditCards() {
      return this.$store.getters['userCreditCards/all']
    },

    /**
     * @returns {PaymentMethod[]}
     */
    selectablePaymentMethods() {
      if (this.currentUser.isGuest()) {
        return this.paymentMethods.filter(
          (method) => method.payment_type !== 'cash_on_delivery'
        )
      } else {
        return this.paymentMethods
      }
    }
  },

  // created() {},

  mounted() {
    this._fetchUserCreditCards()
  },

  methods: {
    selectPaymentMethod(method) {
      if (this.isConfirmed) return

      this.$emit('update:selectedPaymentMethodId', method.id)
      this.$emit('form-edited')
      // this._setUserCreditCardId()  // emit trigger selectedPaymentMethodId 會 delay, 因此無法在 update:selectedPaymentMethodId 直接執行
      this._sendGaCheckoutOptionEvent(method)
    },

    isSelected(method) {
      return method.id === this.selectedPaymentMethodId
    },

    methodIconClass(method) {
      return {
        'fa-credit-card': [
          'credit_card',
          'credit_card_inst_3',
          'credit_card_inst_6',
          'credit_card_inst_12',
          'credit_card_inst_18',
          'credit_card_inst_24',
          'credit_card_inst_30',
          'jko_pay'
        ].includes(method.payment_type),
        'atm-icon': method.payment_type === 'vacc',
        'fa-money': method.payment_type === 'cash_on_delivery',
        'fa-brands fa-line': method.payment_type === 'line_pay'
      }
    },

    imageIcon(method) {
      switch (method.payment_type) {
        case 'line_pay':
          return linePayImage
      }
    },

    methodHasUserCreditCards(paymentMethod) {
      return (
        this.$store.getters[
          'userCreditCards/allByGatewayProviderAndPaymentType'
        ](paymentMethod.gateway_type, paymentMethod.payment_type).length > 0
      )
    },

    defaultUserCreditCardForPaymentMethod(paymentMethod) {
      return this.$store.getters[
        'userCreditCards/defaultForGatewayProviderAndPaymentType'
      ](paymentMethod.gateway_type, paymentMethod.payment_type)
    },

    clickEdit(paymentMethod) {
      this.$buefy.modal.open({
        parent: this,
        component: UserCreditCardList,
        props: {
          gatewayProvider: paymentMethod.gateway_type,
          paymentType: paymentMethod.payment_type
        }
      })
    },

    _setUserCreditCardId() {
      let selectedPaymentMethod = this.paymentMethods.find(
        (method) => method.id === this.selectedPaymentMethodId
      )
      if (selectedPaymentMethod) {
        let userCreditCard = this.defaultUserCreditCardForPaymentMethod(
          selectedPaymentMethod
        )
        this.$emit(
          'update:selectedUserCreditCardId',
          userCreditCard ? userCreditCard.id : null
        )
      } else {
        this.$emit('update:selectedUserCreditCardId', null)
      }
    },

    _fetchUserCreditCards() {
      this.isUserSignedIn &&
        this.userCreditCards.length === 0 &&
        this.$store.dispatch('users/fetchCreditCards')
    },

    async _sendGaCheckoutOptionEvent(method) {
      await this.$store.dispatch('ga4Operation', [
        ['event', 'td_payment_methods', { td_position: method.name }]
      ])
      await this.$store.dispatch('gaOperation', [
        [
          'ec:setAction',
          'checkout_option',
          {
            step: 2,
            option: method.name
          }
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Ecommerce',
            eventAction: 'Select Payment Method'
          }
        ]
      ])
    }
  }
}
</script>
